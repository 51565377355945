import { COMMA, DOT, DOUBLE_ZERO, EMPTY_STRING, GAP_STRING, MINUS, NBSP, RUB, ZERO } from 'constants/constants';
import { ReactText } from 'react';
import { isNil } from 'ramda';
import { DYNAMIC_PRICES_AB_TEST_NAME } from 'constants/constants';
import { IProduct } from 'Modules/Products/types';

export const hex2rgba = (hex: string, alpha: ReactText = 1): string => {
  const red = parseInt(hex.substring(1, 3), 16);
  const green = parseInt(hex.substring(3, 5), 16);
  const blue = parseInt(hex.substring(5, 7), 16);

  return `rgba(${red}, ${green}, ${blue}, ${alpha})`;
};

export const calculateProductPrice = (product: IProduct) => {
  if (product.price) {
    return product.price * product?.amount || product.price_per_kg * product.weight * 0.001;
  } else {
    return product.amount
      ? product.price_per_kg * product.item_weight * product.amount * 0.001
      : product.price_per_kg * product.weight * 0.001;
  }
};

export const productPageMetaTitle = (resultProduct: IProduct | undefined, currentShopGroup: string): string => {
  const isWeightType = resultProduct?.type === 'weight';
  return `${resultProduct?.name} ${resultProduct?.display_weight} за ${
    (isWeightType ? resultProduct?.price_per_kg : resultProduct?.price) + RUB
  } - купить в ${currentShopGroup} с доставкой через Комус Маркет`;
};

export const productPageMetaDescription = (resultProduct: IProduct | undefined, currentShopGroup: string) => {
  return `${resultProduct?.category} — заказывайте в Комус Маркет с доставкой на дом из ${currentShopGroup}. Квалифицированные сотрудники Комус Маркет выберут для Вас самые свежие продукты и качественные товары, аккуратно привезут их в удобное для Вас время.`;
};

export const categoriesPageMetaTitle = (category: string, currentShopGroup: string): string => {
  return `${category} — купить с доставкой из ${currentShopGroup} через Комус Маркет`;
};

export const selectionsPageMetaTitle = (selection: string, currentShopGroup: string): string => {
  return `${selection} в ${currentShopGroup} с доставкой через Комус Маркет`;
};

export const pageMetaDescription = (category: string, currentShopGroup: string): string => {
  return `${category} — заказывайте в Комус Маркет с доставкой на дом из ${currentShopGroup} по лучшей цене. Квалифицированные сотрудники Комус Маркет выберут для Вас самые свежие продукты и качественные товары, аккуратно привезут их в удобное для Вас время.`;
};

export const getHslValuesFromRgb = (rgbString: string): number[] => {
  const rgbValues = rgbString.match(/[0-9.]+/g);

  if (!rgbValues || rgbValues?.length < 3) return [0, 0, 0, 1];

  const r = parseInt(rgbValues[0]) / 255,
    g = parseInt(rgbValues[1]) / 255,
    b = parseInt(rgbValues[2]) / 255;

  const max = Math.max(r, g, b);
  const min = Math.min(r, g, b);

  // Calculating luminance
  const l = (max + min) / 2;
  let h, s: number;

  //Calculating saturation
  if (min === max) {
    s = 0;
  } else if (l <= 0.5) {
    s = (max - min) / (max + min);
  } else {
    s = (max - min) / (2 - max - min);
  }

  //Calculating hue
  if (max === r) {
    h = ((g - b) / (max - min)) * 60;
  } else if (max === g) {
    h = (2 + (b - r) / (max - min)) * 60;
  } else {
    h = (4 + (r - g) / (max - min)) * 60;
  }

  return [
    Math.round(h > 0 ? h : 360 + h),
    Math.round(s * 100),
    Math.round(l * 100),
    rgbValues[3] ? parseFloat(rgbValues[3]) : 1,
  ];
};

const cases = [2, 0, 1, 1, 1, 2];

export const pluralize = (number = 0, declensionTitles: string[]): string => {
  if (number % 100 > 4 && number % 100 < 20) {
    return declensionTitles[2];
  }

  return declensionTitles[cases[Math.min(number % 10, 5)]];
};

export const normalizeWeight = (weight: number): number => Math.round((weight / 1000) * 10) / 10;

export const formattedWeight = (weight: number): string => normalizeWeight(weight).toString().replace('.', ',');

export const numberThousandSeparator = (number: number | string, withoutGap?: boolean): string => {
  if (!number) return EMPTY_STRING;

  const stringNumber = number.toString();
  const result = [];

  for (let i = stringNumber.length; i >= 0; i -= 3) {
    result.unshift(stringNumber.slice(Math.max(0, i - 3), i));
  }

  return result.join(withoutGap ? '' : NBSP).trim();
};

export const numberToFloatString = (value: number): string =>
  (Math.round(value * 100) / 100)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, NBSP)
    .replace('.', ',');

export const numberToFormattedCurrencyString = (val: number): string => {
  const formattedString = numberToFloatString(val);
  const splitted = formattedString.split(',');
  const decimal = splitted[1];
  if (!decimal) {
    return `${formattedString},00`;
  }
  if (decimal.length === 1) {
    return `${formattedString}0`;
  }
  return formattedString;
};

export const floatThousandSeparator = (number: number): string => {
  let withMinus = false;

  if (number < 0) withMinus = true;

  const str = Math.abs(number).toString();
  const result = str.split(DOT);

  result[0] = numberThousandSeparator(result[0]);

  if (result[1]) {
    if (result[1].length === 1) {
      result[1] += ZERO;
    }
    if (result[1].length > 2) {
      result[1] = result[1].slice(0, 2);
    }
  } else {
    result[1] = DOUBLE_ZERO;
  }

  if (withMinus) {
    return MINUS.concat(result.join(COMMA));
  }

  return result.join(COMMA);
};

export const floatToNumberThousandSeparator = (number: number): string => {
  const str = Math.abs(number).toString();
  const result = str.split(DOT);
  return numberThousandSeparator(result[0]);
};

export const calcShowPrice = (price: number): string => floatThousandSeparator(price) + GAP_STRING + RUB;

export const calcPercentDiscount = (old_price: string | number, current_price: string | number): number => {
  const oldPrice = Number(old_price);
  const currentPrice = Number(current_price);
  return Math.round(((oldPrice - currentPrice) / oldPrice) * 100);
};

export const dividePriceToIntAndFract = (price: number): { priceInt: string; priceFract: string } => {
  const [priceInt, priceFract = DOUBLE_ZERO] = price.toString().split(DOT);

  return {
    priceInt: numberThousandSeparator(priceInt),
    priceFract: priceFract.length === 1 ? `${priceFract}0` : priceFract.slice(0, 2),
  };
};

export const redirectToNewDesk = (url: string) => (window.location.href = `${window.location.origin}${url}`);

const PHONE_RGXP = /(\+?\d)(\d\d\d)(\d\d\d)(\d\d)(\d\d)/;

const PHONE_FORMAT = '($2) $3-$4-$5';

export const phoneFormat = (phone: string) => (phone ? phone.replace(PHONE_RGXP, PHONE_FORMAT).trim() : EMPTY_STRING);

export const getRandomInt = (min: number, max: number) => {
  min = Math.floor(min);
  max = Math.floor(max);

  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const getRandomArrayElement = <T>(arr: Array<T>) => (arr.length ? arr[getRandomInt(0, arr.length - 1)] : arr[0]);

export const normalizePrice = (price: number) => Math.round(price * 100) / 100;

export const formatPriceWithRUB = (price: number): string => `${price}${NBSP}${RUB}`;

export const shuffleArray = <T>(array: T[]): T[] => {
  const arrayClone = [...array];
  let currentIndex = arrayClone.length;
  let temporaryValue;
  let randomIndex;
  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = arrayClone[currentIndex];
    arrayClone[currentIndex] = arrayClone[randomIndex];
    arrayClone[randomIndex] = temporaryValue;
  }
  return arrayClone;
};

export const getRandomString = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z0-9]+/g, '')
    .substr(0, 10);

export const isBrowser = typeof window !== 'undefined';
export const isServer = !isBrowser;

export const truncateString = (param: string | number, char = 100) => String(param).substring(0, char);

export const getTestParticipationParam = (isIncludedInPricesTest?: boolean) => {
  if (!isNil(isIncludedInPricesTest)) {
    return { [DYNAMIC_PRICES_AB_TEST_NAME]: String(Number(isIncludedInPricesTest)) };
  }
};

export const truncateObjValues = (obj: { [key: string]: string | number }, chars = 100) => {
  const truncatedObj: { [key: string]: string | number } = {};
  Object.keys(obj).forEach(k => {
    truncatedObj[k] = truncateString(obj[k], chars);
  });
  return truncatedObj;
};

export const isTouchEnabled = () => {
  if (isBrowser) {
    return 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
  }

  return false;
};

export const declOfNum = (number: number, words: string[]) => {
  return words[
    number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
  ];
};
