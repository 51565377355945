import { DEPLOY_ENV_NOT_PROD, NODE_ENV_IS_PRODUCTION } from '../config/config';
import { ModalType } from './constants/enums';
import { REFERRER_COOKIE, REF_COOKIE, getCookieDomain, ADMITAD_COOKIE } from './constants/cookie';
import React, { useEffect } from 'react';
import { AuthTokenService } from '@igooods/axios-helpers';
import Cookie from 'js-cookie';
import { RedesignedGlobalStyle } from './redesignedGlobalStyles';
import ModalModuleContainer from './Modules/Modals';
import PassportModal from 'Containers/Modals/Passport';
import ConfigChanger from './Components/ConfigChanger';
import NotificationsModuleContainer from './Modules/Notifications';
import Routes from './routes';
import { getCartId } from 'Modules/Cart/selectors';
import { getCurrentCityId } from './Modules/SearchAddress/selectors';
import { getCurrentShopId } from 'Modules/Shops/selectors';
import { checkIsTempUser, getCurrentUser } from 'Modules/AAA/selectors';
import { gtagConfig, useGtagUserProperties } from './utils/gtag';
import qs from 'qs';
import { useHistory } from 'react-router';
import { useSelector } from 'react-redux';
import { IApplicationState, useThunkDispatch } from 'reduxStore/types';
import CriteoClient from 'utils/criteo';
import useDeskClient from 'utils/useDesk';
import { Fonts } from 'assets/fonts';
import { Helmet } from 'react-helmet';
import loadable from '@loadable/component';
import ModalLoading from 'Containers/Modals/ModalLoading';
import { getFullCurrentDate } from 'utils/date';
import useSessionStart from 'utils/hooks/useSessionStart';
import useFirstTimeVisit from 'utils/hooks/useFirstTimeVisit';
import useUserSetsListInit from 'utils/hooks/useUserSetsListInit';
import AccountActivationModal from './Containers/Modals/AccountActivation';
import ModalActions from './Modules/Modals/actions';

export const INVITE_PROMOCODE = 'invite_promocode';

const AuthModal = loadable(() => import(/* webpackChunkName: "ApplyModal" */ 'Containers/Modals/Auth'), {
  fallback: <ModalLoading />,
});
const DiscountModal = loadable(() => import(/* webpackChunkName: "DiscountModal" */ 'Containers/Modals/Discount'), {
  fallback: <ModalLoading />,
});
const NetworkErrorModal = loadable(
  () => import(/* webpackChunkName: "NetworkErrorModal" */ 'Components/NetworkErrorModal'),
  {
    fallback: <ModalLoading />,
  }
);

const App: React.FC = () => {
  const dispatch = useThunkDispatch();
  const history = useHistory();
  const currentOrderId = useSelector(getCartId);
  const shopId = useSelector(getCurrentShopId);
  const { isOpened, modalType } = useSelector((state: IApplicationState) => state.modals);
  const { name, email, id, phone, is_temp, unconfirmed_phone } = useSelector(getCurrentUser);
  const cityId = useSelector(getCurrentCityId);
  const isUserTemp = useSelector(checkIsTempUser);
  const setCommonUserProperties = useGtagUserProperties();
  const openAuthModal = () => dispatch(ModalActions.openModal(ModalType.Auth));

  useUserSetsListInit();

  useEffect(() => {
    if (!isUserTemp && !name && !email && !isOpened && modalType !== 'Auth') {
      openAuthModal();
    }
  }, [isUserTemp, name, email, isOpened, modalType, openAuthModal]);

  useEffect(() => {
    const clientId = Cookie.get('_ga');
    if (clientId?.includes(String(id))) Cookie.remove('_ga', { domain: '.igooods.ru' });
  }, [id]);

  useEffect(() => {
    const oldTokenName = 'igooods:authToken';
    const oldToken = window.localStorage.getItem(oldTokenName);
    if (oldToken) {
      const parsedToken = JSON.parse(oldToken);

      if (parsedToken && parsedToken.id !== AuthTokenService.get()?.id && isUserTemp) {
        AuthTokenService.set({ token: parsedToken.token, id: +parsedToken.id }, getCookieDomain().domain);
        window.localStorage.removeItem(oldTokenName);
        window.location.reload();
      }
    }
  }, [isUserTemp]);

  useEffect(() => {
    setCommonUserProperties();
  }, [setCommonUserProperties]);

  useEffect(() => {
    if (!is_temp && phone) {
      window?.AF && window.AF('pba', 'setCustomerUserId', String(id));
      useDeskClient.init(phone, name);
    }
    if (!CriteoClient.defaultEvents.length) {
      CriteoClient.init(email);
    }
  }, [cityId, email, id, is_temp, name, phone, unconfirmed_phone]);

  useEffect(() => {
    const data = {
      ...(id && { user_id: id }),
      ...(currentOrderId && { order_id: currentOrderId }),
      ...(shopId && { shop_id: shopId }),
    };

    gtagConfig(data);
  }, [id, currentOrderId, shopId]);

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.href.split('?')[1]);
    const id = queryParams.get('base');
    const token = queryParams.get('base_type');
    const redirectTo = queryParams.get('redirect_to');

    if ((id && token) || redirectTo) {
      history.replace(window.location.pathname.split('?')[0]);
    }
  }, [dispatch, history, id]);

  useEffect(() => {
    const { ref } = qs.parse(window.location.search.slice(1));
    const { referrer } = document;

    const options = {
      expires: 30,
      domain: '.igooods.ru',
    };

    if (ref) {
      Cookie.set(REF_COOKIE, ref, options);
    }

    if (referrer) {
      Cookie.set(REFERRER_COOKIE, referrer, options);

      const referrerUrl = new URL(referrer);

      const invitePromocode = referrerUrl.searchParams.get('code');
      if (invitePromocode) {
        localStorage.setItem(INVITE_PROMOCODE, invitePromocode);
      }
    }
  }, []);

  const ErrorBoundaryWrapper = NODE_ENV_IS_PRODUCTION
    ? require('./lib/bugsnag').default.getPlugin('react')
    : React.Fragment;

  useSessionStart();
  useFirstTimeVisit();

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.href.split('?')[1]);
    const admitad_uid = queryParams.get('admitad_uid');

    if (admitad_uid && !Cookie.get(ADMITAD_COOKIE)) {
      const cookie = {
        uid: admitad_uid,
        set_at: getFullCurrentDate(),
      };

      const options = {
        expires: 15,
        domain: '.igooods.ru',
      };

      Cookie.set(ADMITAD_COOKIE, JSON.stringify(cookie), options);
    }
  }, []);

  return (
    <ErrorBoundaryWrapper>
      <Helmet>
        <meta
          content="Доставка продуктов питания и товаров в Москве из магазинов партнеров. Комус Маркет +7 (495) 729-54-71"
          property="og:description"
        />
        <meta content="https://market.komus.ru/" property="og:url" />
        {/* telegram */}
        <meta
          property="twitter:image"
          content="https://f25229e2-a15b-48b4-8f06-dfecbbfb1693.selcdn.net/komus-yest.png"
        />
        {/* vk */}
        <meta property="vk:image" content="https://f25229e2-a15b-48b4-8f06-dfecbbfb1693.selcdn.net/komus-yest.png" />
        {/* other */}
        <meta property="og:image" content="https://f25229e2-a15b-48b4-8f06-dfecbbfb1693.selcdn.net/komus-yest.png" />
        <meta content="Komus.Yest – заказ и доставка продуктов в офис в Москве." property="og:title" />
      </Helmet>
      <RedesignedGlobalStyle />
      <Fonts />
      <Routes />
      <ModalModuleContainer>
        <AuthModal isInjected={false} type={ModalType.Auth} />
        <DiscountModal type={ModalType.Discount} />
        <NetworkErrorModal type={ModalType.NetworkError} />
        <PassportModal type={ModalType.Passport} />
        <AccountActivationModal type={ModalType.AccountActivation} />
      </ModalModuleContainer>
      <NotificationsModuleContainer />
      {DEPLOY_ENV_NOT_PROD && <ConfigChanger />}
    </ErrorBoundaryWrapper>
  );
};

export default App;
